import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Delete } from '@wix/wix-ui-icons-common/on-stage';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { classes as classesActionsMenu } from '../../ManageButton.st.css';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  ActionsMenuType,
  ModalType,
} from '../../../../../../types';
import { ActionsButtonsDataHooks, CancelDataHooks } from '../../../datahooks';
import { useApplicationContext } from '../../../../../../core/hooks/useApplicationContext';
import { CancellationModalProps } from '../../../../../../core/ModalFacade/modals/CancellationModal';

export interface CancelProps extends ActionButtonProps {}

export const Cancel: React.FC<CancelProps> = ({
  type,
  onActionClick,
  group,
}) => {
  const { t } = useTranslation();
  const {
    actions: { showModal: onShowModal },
  } = useApplicationContext();
  let content;

  if (type === ActionButtonUiTypes.MENU) {
    content = (
      <div data-hook={ActionsButtonsDataHooks.CancelMenu}>
        <ActionsMenuLayout className={classesActionsMenu.actionMenuLayout}>
          <ActionsMenuLayout.Item
            onClick={() => {
              onShowModal<CancellationModalProps>(ModalType.Cancellation, {
                group,
              });
              onActionClick?.(ActionsMenuType.CANCEL);
            }}
            prefixIcon={<Delete />}
            content={t('app.my-bookings-widget.book-cancel.label')}
          />
        </ActionsMenuLayout>
      </div>
    );
  }

  return <div data-hook={CancelDataHooks.Main}>{content}</div>;
};
