import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  memberAreaDesignTitleColor: StyleParamType.Color;
  memberAreaDesignTitleFont: StyleParamType.Font;
  memberAreaDesignTitleMobileFontSize: StyleParamType.Number;
  memberAreaDesignDescriptionColor: StyleParamType.Color;
  memberAreaDesignDescriptionFont: StyleParamType.Font;
  memberAreaDesignDescriptionMobileFontSize: StyleParamType.Number;
  memberAreaDesignTextColor: StyleParamType.Color;
  memberAreaDesignTextFont: StyleParamType.Font;
  memberAreaDesignTextMobileFontSize: StyleParamType.Number;
  memberAreaDesignSecondaryTextColor: StyleParamType.Color;
  memberAreaDesignSecondaryTextFont: StyleParamType.Font;
  memberAreaDesignSecondaryTextMobileFontSize: StyleParamType.Number;
  memberAreaDesignLinksColor: StyleParamType.Color;
  memberAreaDesignLinksFont: StyleParamType.Font;
  memberAreaDesignNoBookingsButtonTextColor: StyleParamType.Color;
  memberAreaDesignNoBookingsButtonFont: StyleParamType.Font;
  memberAreaDesignNoBookingsButtonColor: StyleParamType.Color;
  memberAreaDesignNoBookingsButtonBorderColor: StyleParamType.Color;
  memberAreaDesignNoBookingsButtonBorderWidth: StyleParamType.Number;
  memberAreaDesignNoBookingsButtonBorderRadius: StyleParamType.Number;
  memberAreaDesignNoBookingsButtonMobileFontSize: StyleParamType.Number;
  memberAreaDesignLinksMobileFontSize: StyleParamType.Number;
  memberAreaDesignSelectedTabBorderColor: StyleParamType.Color;
  memberAreaDesignTabsBorderColor: StyleParamType.Color;
  memberAreaDesignListDividerColor: StyleParamType.Color;
  memberAreaDesignListDividerWidth: StyleParamType.Number;
  memberAreaDesignManageButtonTextColor: StyleParamType.Color;
  memberAreaDesignManageButtonColor: StyleParamType.Color;
  memberAreaDesignManageBorderColor: StyleParamType.Color;
  memberAreaDesignManageFont: StyleParamType.Font;
  memberAreaDesignManageButtonBorderWidth: StyleParamType.Number;
  memberAreaDesignManageButtonBorderRadius: StyleParamType.Number;
  memberAreaDesignManageButtonMobileFontSize: StyleParamType.Number;
  memberAreaDesignBGColorBordersColor: StyleParamType.Color;
  memberAreaDesignBorderColor: StyleParamType.Color;
  memberAreaDesignBorderWidth: StyleParamType.Number;
  memberAreaDesignBorderRadius: StyleParamType.Number;
};
export default createStylesParams<IStylesParams>({
  memberAreaDesignTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 28,
    }),
  },
  memberAreaDesignTitleMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  memberAreaDesignDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignDescriptionMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  memberAreaDesignTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignTextMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  memberAreaDesignSecondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  memberAreaDesignSecondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  memberAreaDesignSecondaryTextMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  memberAreaDesignLinksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignLinksFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignNoBookingsButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignNoBookingsButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  memberAreaDesignNoBookingsButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  memberAreaDesignNoBookingsButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  memberAreaDesignNoBookingsButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  memberAreaDesignNoBookingsButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  memberAreaDesignNoBookingsButtonMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  memberAreaDesignLinksMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  memberAreaDesignSelectedTabBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  memberAreaDesignTabsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  memberAreaDesignManageButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  memberAreaDesignManageButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  memberAreaDesignManageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  memberAreaDesignManageFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignManageButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  memberAreaDesignManageButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  memberAreaDesignManageButtonMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  memberAreaDesignListDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  memberAreaDesignListDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  memberAreaDesignBGColorBordersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  memberAreaDesignBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  memberAreaDesignBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  memberAreaDesignBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
