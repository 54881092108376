import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { isRescheduleAble } from '../../../../utils/bookInfo';
import { ActionButtonUiTypes, BookingsGroup } from '../../../../types';
import { useGroupHeaderGetters } from '../../../hooks/useGroupHeaderGetter';
import { CancellationModalDataHooks } from '../../../../components/MyBookings/Widget/datahooks';
import {
  Modal,
  ModalProps,
} from '../../../../components/MyBookings/Components/Modal';
import { useApplicationContext } from '../../../hooks/useApplicationContext';
import { Reschedule } from '../../../../components/MyBookings/Widget/ManageButton/ActionsButtons/Reschedule';

export interface CancellationModalProps {
  group: BookingsGroup;
}

export const CancellationModal: React.FC<
  CancellationModalProps & ModalProps
> = ({ group }) => {
  const { actions } = useApplicationContext();
  const { t } = useTranslation();
  const { getTitle } = useGroupHeaderGetters();

  const handlePrimaryButtonClick = () => {
    // TODO: implement
  };

  const handleSecondaryButtonClick = () => {
    actions.closeModal();
  };

  const renderCancellationModalWithRescheduleContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.Reschedule}
      modalTitle={t(
        'app.my-bookings-widget.cancellation-modal-with-reschedule.title',
      )}
      modalSubtitle={t(
        'app.my-bookings-widget.cancellation-modal-with-reschedule.content',
      )}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal-with-reschedule.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        component: (
          <Reschedule group={group} type={ActionButtonUiTypes.BUTTON} />
        ),
      }}
    />
  );

  const renderCancellationModalWithoutRescheduleContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.Cancellation}
      modalTitle={t('app.my-bookings-widget.cancellation-modal.title')}
      modalSubtitle={t('app.my-bookings-widget.cancellation-modal.content', {
        serviceName: getTitle(group),
      })}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal.secondary-button.label',
          ),
          onClick: handleSecondaryButtonClick,
        },
      }}
    />
  );

  return (
    <div data-hook={CancellationModalDataHooks.Main}>
      {isRescheduleAble(group)
        ? renderCancellationModalWithRescheduleContent()
        : renderCancellationModalWithoutRescheduleContent()}
      ;
    </div>
  );
};
