import React from 'react';
import { config } from './config';
import { useApplicationContext } from '../hooks/useApplicationContext';
import { ModalFacadeDataHooks } from '../../components/MyBookings/Widget/datahooks';

export interface ModalProps {
  onClose: () => void;
}

export const ModalFacade: React.FC = () => {
  const { modalContext } = useApplicationContext();

  if (!modalContext) {
    return null;
  }

  const { type, props } = modalContext;

  return (
    <div data-hook={ModalFacadeDataHooks.Main}>
      {config[type]({ ...props })}
    </div>
  );
};
