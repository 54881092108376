import React, { FC, useCallback, useEffect, useState } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TabbedContent, TabsAlignment, Spinner } from 'wix-ui-tpa/cssVars';
import { classes, st } from './BookingsLayout.st.css';
import {
  ApplicationContext,
  useApplicationContext,
} from '../../../../core/hooks/useApplicationContext';
import { TabTypes } from '../../../../types';
import { ControllerActions } from '../../../../actions/actions';
import { BookingsList } from './BookingsList';
import { TimezoneDropdown } from '../TimezoneDropdown';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { BookingsLayoutDataHooks } from '../datahooks';
import { EmptyState } from './EmptyState';

type ITabConfig = {
  [key in TabTypes]: {
    tabIndex: number;
    id: string;
    title: (settings: ISettingsContextValue) => string;
    isDataLoaded: (data: ApplicationContext) => boolean;
    isEmpty: (data: ApplicationContext) => boolean;
    getData: (actions: ControllerActions) => void;
  };
};

const TabConfig: ITabConfig = {
  [TabTypes.UPCOMING]: {
    tabIndex: 0,
    id: TabTypes.UPCOMING,
    title: (settings: ISettingsContextValue) =>
      getContent({
        settings,
        settingsParam: settingsParams.membersAreaUpcomingText,
      }),
    isDataLoaded: ({ upcomingData }) => Boolean(upcomingData),
    isEmpty: ({ upcomingData }) => !upcomingData?.groups.length,
    getData: (actions: ControllerActions) => actions.getUpcoming(),
  },
  [TabTypes.HISTORY]: {
    tabIndex: 1,
    id: TabTypes.HISTORY,
    title: (settings: ISettingsContextValue) =>
      getContent({
        settings,
        settingsParam: settingsParams.membersAreaHistoryText,
      }),
    isDataLoaded: ({ historyData }) => Boolean(historyData),
    isEmpty: ({ historyData }) => !historyData?.groups.length,
    getData: (actions: ControllerActions) => actions.getHistory(),
  },
};

const BookingsLayout: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const applicationContext = useApplicationContext();
  const { actions, selectedTab, selectedTimezone, historyData, upcomingData } =
    applicationContext;
  const [tabIndex, setTabIndex] = useState(
    TabConfig[selectedTab.type].tabIndex,
  );

  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();

  useEffect(() => {
    if (!TabConfig[selectedTab.type].isDataLoaded(applicationContext)) {
      setIsLoading(true);
      TabConfig[selectedTab.type].getData(actions);
    } else if (selectedTimezone?.type && selectedTab?.type) {
      setIsLoading(false);
    }
  }, [selectedTimezone?.type, selectedTab.type, historyData, upcomingData]);

  const handleOnTabClick = (id: number) => {
    const selectedTabType: TabTypes = Object.keys(TabConfig).find(
      (key: string) => TabConfig[key as TabTypes].tabIndex === id,
    ) as TabTypes;

    setTabIndex(id);
    actions.setSelectedTab({ type: selectedTabType });
  };

  const getItems = useCallback(() => {
    return [
      {
        id: TabConfig[TabTypes.UPCOMING].id,
        title: TabConfig[TabTypes.UPCOMING].title(settings),
      },
      {
        id: TabConfig[TabTypes.HISTORY].id,
        title: TabConfig[TabTypes.HISTORY].title(settings),
      },
    ];
  }, []);

  return (
    <div
      data-hook={BookingsLayoutDataHooks.Main}
      className={st(classes.root, { isMobile })}
    >
      <TabbedContent
        data-hook={BookingsLayoutDataHooks.TabbedContent}
        className={classes.tabbedContent}
        activeTabIndex={tabIndex}
        onTabClick={handleOnTabClick}
        alignment={isRTL ? TabsAlignment.right : TabsAlignment.left}
        items={getItems()}
      >
        {() => {
          if (isLoading) {
            return (
              <div className={classes.spinnerWrapper}>
                <Spinner
                  className={classes.spinner}
                  data-hook={BookingsLayoutDataHooks.Spinner}
                  diameter={50}
                  isCentered={true}
                />
              </div>
            );
          }

          if (TabConfig[selectedTab.type].isEmpty(applicationContext)) {
            return <EmptyState />;
          }

          return (
            <div className={classes.content}>
              <TimezoneDropdown />
              <BookingsList />
            </div>
          );
        }}
      </TabbedContent>
    </div>
  );
};

export default BookingsLayout;
