import { BookingsGroup, BookingStatus, OfferingTypes } from '../../types';

export const isCourse = (group: BookingsGroup): boolean => {
  const firstBooking = group.bookings?.[0];

  return firstBooking?.offering?.type === OfferingTypes.COURSE;
};

export const isPendingApproval = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) => booking.status === BookingStatus.PENDING_APPROVAL,
  );
};

export const isWaitList = (group: BookingsGroup): boolean => {
  return group.bookings.some(
    (booking) => booking.status === BookingStatus.PENDING,
  );
};

export const isBookAnotherAble = (group: BookingsGroup): boolean => {
  return !!(
    !isCourse(group) &&
    group.bookings.length === 1 &&
    group.allowedActions?.bookAnother
  );
};

export const isRescheduleAble = (group: BookingsGroup): boolean => {
  return !!(
    group?.allowedActions?.reschedule &&
    group.bookings.every((booking) => {
      return (
        !booking.offering?.isBookingApprovalFlow &&
        booking.status !== BookingStatus.PENDING &&
        booking.offering?.type !== OfferingTypes.COURSE &&
        !booking.isDynamicPrice
      );
    })
  );
};

export const isCancelAble = (group: BookingsGroup): boolean => {
  return !!group?.allowedActions?.cancel;
};

export const isAvailableOnline = (group: BookingsGroup): boolean => {
  return (
    group.bookings.some((booking) => !!booking.videoConference) &&
    !isWaitList(group) &&
    !isPendingApproval(group)
  );
};
